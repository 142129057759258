import { Controller } from "stimulus";
import flatpickr from "flatpickr";
export default class extends Controller {
  connect() {
    $(`#${this.element.dataset.modal}`).modal("show");
    if (this.element.dataset.error == "true") {
      const element = $("#license-plate-form-submit");
      Rails.enableElement(element[0]);
      element.html(element.data("originalMsg"));
    }
    if (this.element.dataset.emailConfirm == "true") {
      if (
        confirm(
          "There is already a user with this email. Do you want to make them a sub user on this account?"
        ) == true
      ) {
        $("#user_force_update_parent_id").val(true);
        document.getElementById("sub-users-form").submit();
      }
    }
    flatpickr(".flatpicker", {
      dateFormat: "Y-m-d",
      disableMobile: true,
      mode: "range",
      wrap: true,
      defaultDate: $(".flatpicker").data("range"),
    });
  }
}
