import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    $(this.element)
      .find(".license-plate-input")
      .on("click", function () {
        $(this).parent(".input-group").addClass("selected");
        $(this).parent(".input-group").find("input").removeAttr("required");
      });
    $(this.element)
      .find(".input-group__license-plate .use-vin")
      .on("click", function () {
        $(this).parents(".input-group").removeClass("selected");
        $(this).parent(".input-group").find("input").attr("required", true);
      });
  }
}
