import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    $(".toast").toast("show");
    const toasts = document.querySelectorAll(".toast");
    toasts.forEach((toastItem) => {
      toastItem.addEventListener("hidden.bs.toast", function () {
        this.remove();
      });
    });
  }
}
