import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    // document.getElementById("loading-gif")?.remove();
    this.element.querySelectorAll(".header-filter").forEach((item) => {
      item.addEventListener("click", (e) => this.filterResults(e));
    });

    this.element.querySelectorAll(".scroller").forEach((item) => {
      item.addEventListener("click", (e) => this.scroll(e));
    });
  }
  filterResults(e) {
    e.preventDefault();
    const targetClass = e.currentTarget.dataset.target;
    if (targetClass == "all") {
      $(".header-result").removeClass("d-none");
    } else if (targetClass == "options") {
      $(".header-result").removeClass("d-none");
      $(
        ".header-mechanical, .header-exterior, .header-interior, .header-safety"
      ).addClass("d-none");
    } else {
      $(".header-result").addClass("d-none");
      $(`.header-${targetClass}`).removeClass("d-none");
    }
  }

  scroll(e) {
    const element = document.getElementById(e.target.dataset.targetId);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}
