import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.querySelectorAll(".user-status").forEach((item) => {
      item.addEventListener("change", (e) => this.updateStatus(e));
    });
  }
  updateStatus(e) {
    e.preventDefault();
    const userId = e.target.dataset.userId;
    const userStatus = e.target.value;

    $.post(`/users/${userId}/update`, { user: { status: userStatus } });
  }
}
