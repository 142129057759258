import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    window.stripe = Stripe(window.stripe_publishable_key);

    if (this.element.dataset.sessionId) {
      window.stripe.redirectToCheckout({
        sessionId: this.element.dataset.sessionId,
      });
    } else {
      jQuery(document).on(
        "click touchstart",
        ".btn-buy, .btn-new-checkout",
        function () {
          if ($(this).data("loading") != true) {
            const dis = this;
            console.log("fasd");
            $(this).data("loading", true);
            $(this).html('<i class="fa fa-spinner fa-spin"></i>');
            let data = { plan_name: $(this).data("name") };
            if ($(this).hasClass("btn-new-checkout")) {
              data.trade_show = true;
            }
            $.post("/get_stripe_session", data, function (data) {
              $(dis).data("loading", false);
              window.stripe.redirectToCheckout({
                sessionId: data.sessionId,
              });
            });
          }
        }
      );
    }
  }
}
