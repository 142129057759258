import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
// import "../js/turbo_hack.js";
Rails.start();
ActiveStorage.start();
window.Rails = Rails;
import jquery from "jquery";
window.$ = window.jquery = jquery;
import AOS from "aos";
window.AOS = AOS;

import "@fortawesome/fontawesome-free/css/all";
import "controllers";
import "../js/bootstrap_js_files.js";
import "../js/landing_main.js";
