import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.controller = this;
    //document.getElementById("loading-gif")?.remove();

    // if ($("#search-results").val() != "") {
    //   this.filterSearch();
    // }
    $("#search-results").on("keyup", function (e) {
      document.getElementById("search-form").controller.filterSearch(e);
    });
    $("#search-alias-results").on("keyup", function (e) {
      document.getElementById("search-form").controller.filterAliasSearch(e);
    });
  }

  submit(e) {
    this.filterAliasSearch(e, true);
  }

  toggle() {
    $("#licenseplatedata-modal").modal("hide");
    $("#licenseplatedata-modal").remove();

    $("#turbo_modal").removeAttr("src");
  }

  filterAliasSearch(event, submit = false) {
    if ((event !== null && event.key === "Enter") || submit) {
      if ($("#search-alias-results").val() != "") {
        window.location.href = `${this.element.dataset.url}?query=${$(
          "#search-alias-results"
        ).val()}`;
      } else {
        window.location.href = this.element.dataset.url;
      }
    }
  }

  filterSearch(event = null) {
    if (event !== null && event.key === "Enter") {
      event.preventDefault();
    }
    var value = $("#search-results").val().toLowerCase();
    let filteredNodeCount = 0;
    $("li.header-result").filter(function () {
      let found = $(this).text().toLowerCase().indexOf(value) > -1;
      $(this).toggle(found);
      filteredNodeCount += found == true ? 1 : 0;
    });
    if (filteredNodeCount == 0) {
      $("#current-query").text($("#search-results").val());
      $(".no-result-wrapper").removeClass("d-none");
    } else {
      $(".no-result-wrapper").addClass("d-none");
    }
  }
}
