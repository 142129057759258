import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    $(`#${this.element.dataset.modal}`).modal("toggle");
  }
  close() {
    if (!!this.element.dataset.targetUpdate) {
      $(this.element.dataset.targetUpdate).val(
        $(this.element.dataset.sourceUpdate).val()
      );
    }
    if (!!this.element.dataset.modal) {
      $(`#${this.element.dataset.modal}`).modal("hide");
      $(`#${this.element.dataset.modal}`).remove();
    } else {
      $(this.element).modal("hide");
      if ($(this.element).data("removeModal") != false) {
        this.element.remove();
      }
    }
    $(".form-submit").each(function () {
      const element = $(this);
      Rails.enableElement(element[0]);
      element.html(element.data("originalMsg"));
    });
    const element = $("#license-plate-form-submit");
    if (element[0]) {
      Rails.enableElement(element[0]);
      element.html(element.data("originalMsg"));
    }
    $("#turbo_modal").removeAttr("src");
  }

  escClose(event) {
    if (event.key === "Escape") this.close();
  }
}
